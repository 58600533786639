$theme: core;
@use "design-system" as ds;

.feedLayout {
  display: flex;
  flex-direction: column;
  position: relative;
}

.pageContainer {
  margin: 0 auto ds.spacing() auto;
}
